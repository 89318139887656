.pagination {
  margin-top: 24px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 28px;
}
.page_link {
  height: 100%;
  min-width: 28px;
  padding: 6px 4px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 2px;
  background-color: transparent;
  border: 2px solid #1890ff;
  color: #1890ff;
  margin-right: 6px;
}

.page_link:hover {
  cursor: pointer;
}

.page_link.not_filled {
  width: 28px;
  text-align: center;
  border: none;
  font-weight: 600;
}

.page_link.active {
  color: #ffffff;
  background-color: #1890ff;
}

.page_link.switch {
  border: none;
  background-color: #e2e5f0;
}

.page_link.switch.left,
.page_link.switch.right {
  width: 28px;
  height: auto;
  background: #393B40;
}
