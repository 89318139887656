.wrapper {
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  gap: 24px;
}
.block {
  display: flex;
  position: relative;
}
.value,
.title {
  font-weight: 400;
  font-size: 16px;
}
.title {
  margin-right: 8px;
  color: #DBE0F2;
}
.value {
  color: white;
}
.value.header {
  align-items: center;
  display: flex;
  transition: 0.5s;
  opacity: 1;
  gap: 8px;
}
.value.header img {
  width: 14px;
  height: 14px;
}
.value.header:hover {
  cursor: pointer;
  opacity: 0.5;
}
.selector {
  width: 169px;
  background-color: #ffffff;
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 1000;
  border: 2px solid #dbe0f2;
  border-radius: 6px;
}
.option {
  padding: 12px;
  color: #2c2e35;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition: 0.5s;
}
.option:hover {
  background-color: #dbe0f2;
  cursor: pointer;
}
.option:not(:last-child) {
  border-bottom: 2px solid #dbe0f2;
}

@media (max-width: 768px) {
  .wrapper {
    flex-direction: column;
    gap: 8px;
  }
  .block {
    flex-direction: column;
    gap: 4px;
  }
  .selector {
    top: 52px;
    left: 0;
  }
}
