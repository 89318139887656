.table {
  max-height: 100px;
  table-layout: fixed;
  word-break: break-all;
  border-collapse: collapse;
  border-radius: 8px;
  border-style: hidden;
  box-shadow: 0 0 0 1px #dbe0f2;
  width: 100%;
}
.table th {
  background: rgba(0, 0, 0, 0.30);
  color: white;
  font-size: 14px;
  font-weight: 500;
}
.td_table {
  word-break: normal;
}
.table td,
th {
  border: 1px solid #DBE0F2;
  padding: 0.8vh 0.9vw;
  text-align: left;
  color: white;
}
.table td {
  font-size: 14px;
}
.empty_table {
  text-align: center !important;
  height: 15vh;
}
.empty_table img {
  margin: 46px;
  max-width: 100% !important;
  max-height: 100% !important;
  height: auto !important;
  width: auto !important;
}
th:first-child {
  border-top-left-radius: 8px;
}
th:last-child {
  border-top-right-radius: 8px;
}
.table td img {
  display: inline-flex;
  width: 1.5vw;
  height: auto;
  max-width: 20px;
}
