.navBar {
  flex-direction: column;
  height: 100vh;
  width: 18%;
  background: rgba(0, 0, 0, 0.30);
}
.logo {
  margin-top: 13%;
  margin-bottom: 13%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logoImg {
  width: 9vw;
  height: auto;
}
.button,
.activeButton {
  padding: 1.8vh 1.5vw;
  flex: 1;
  width: 100%;
  font-size: 1rem;
  font-weight: 500;
  color: white;
}

.button:hover,
.activeButton:hover{
  cursor: pointer;
  opacity: 0.5;
}

.activeButton {
  background-color: #1E82F7;
  color: white;
  border-right: 3px solid #fff;
}

.button img,
.activeButton img {
  width: 0.8rem;
  height: auto;
  margin-top: 3px;
  margin-right: 0.6vw;
}
@media (max-width: 1024px) {
  .navBar {
    padding-top: 64px;
    height: 100%;
    width: 100% !important;
    margin-left: 500px;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.60);
    z-index: 1000;
  }
  .button {
    padding-left: 24px !important;
  }
  .logo {
    position: absolute;
    display: none;
  }
  .button img {
    margin-right: 7px;
  }
  .logoImg {
    display: none;
  }
}
@media (max-width: 768px) {
  .navBar {
    padding-top: 20px;
    height: 100%;
    width: 100% !important;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.90);
    z-index: 1000;
    margin: 0;
    margin-top: 44px;
  }
  .button,
  .activeButton {
    padding-left: 24px !important;
  }
  .logo {
    position: absolute;
    display: none;
  }
  .button img {
    margin-right: 7px;
  }
  .logoImg {
    display: none;
  }
}
