.dropdown {
  z-index: 1;
  color: #2c2e35;
  font-size: 14px;
  font-weight: 400;
  max-width: 440px;
  width: 100%;
  position: relative;
}
.dropdown_head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.30);
  border-radius: 4px;
  border: 1px solid #696E7E;
  margin-bottom: 6px;
  padding: 10px 12px;
  color: white;
}
.dropdown_head:hover,
.dropdown ul li:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.30);
}
.dropdown_head img {
  width: 14px;
  height: 14px;
}

.dropdown ul {
  padding: 0px;
  border: 1px solid #696E7E;
  border-radius: 4px;
  background-color: #1C1D20;
  list-style-type: none;
  position: absolute;
  width: 100%;
  color: white;
}

.dropdown ul li {
  padding: 10px 12px;
}

.dropdown ul li:not(:last-child) {
  border-bottom: 1px solid #DBE0F2;
  text-decoration: none;
}
.applications_table_filter {
  margin-right: 8px;
  margin-bottom: 16px;
}
.application_filter_button {
  border: 1.2px solid #dbe0f2;
  background-color: transparent;
  font-size: 14px;
  font-weight: 400;
  color: #8e95b0;
  border-radius: 70px;
  padding: 4px 16px;
  margin-right: 8px;
}
.application_filter_button:hover {
  cursor: pointer;
}
.application_filter_button.active {
  background-color: #1890ff;
  border-color: #1890ff;
  color: #ffffff;
}

.input {
  margin-left: 5px;
  margin-bottom: 12px;
  width: 100%;
  height: 40px;
  padding: 10px 12px;
  outline: none;
  background-color: rgba(0, 0, 0, 0.30);
  color: white;
  border: 1.5px solid #696E7E;
}
