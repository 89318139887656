.actions {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.actions span {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  opacity: 1;
}
.actions span:hover {
  opacity: 0.5;
  cursor: pointer;
}
.status {
  color: #ffa216;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.content {
  margin-top: 24px;
  border-radius: 8px;
  border: 2px solid #dbe0f2;
  padding: 8px;
}
.token {
  display: flex;
  flex-direction: row;
  gap: 6px;
  margin-bottom: 16px;
}
.textToken {
  word-break: break-all;
}
.copy {
  width: 20px !important;
  height: fit-content !important;
  transition: 0.5s;
  opacity: 1;
}
.copy:hover {
  cursor: pointer;
  opacity: 0.5;
}
.token {
  max-width: 100% !important;
  width: 100% !important;
  height: auto !important;
}

@media (max-width: 1024px) {
  .actions span {
    text-align: right;
  }
}
@media (max-width: 768px) {
  .actions span {
    text-align: center;
  }
  .actions {
    flex-direction: row;
    gap: 16px;
  }
}
