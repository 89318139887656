* {
  margin: 0;
}

.wrapper {
  width: 460px;
}

.logo {
  margin-bottom: 8px;
}

.wrapper h1 {
  font-weight: 500;
  font-size: 20px;
  color: #2c2e35;
  margin-bottom: 24px;
  text-align: center;
}

.wrapper span {
  font-weight: 500;
  color: #1890ff;
  font-size: 14px;
}

.wrapper span:hover {
  cursor: pointer;
  opacity: 0.5;
}

.form p {
  color: #fe5b37;
  font-size: 14px;
  font-weight: 400;
}

.email {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  gap: 8px;
  text-decoration: none;
  color: #1890ff;
}

@media (max-width: 1024px) {
  .wrapper {
    width: 448px;
  }
}

@media (max-width: 768px) {
  .wrapper {
    width: 100%;
  }
  .logo {
    margin-bottom: 24px;
  }
}
