.info-block {
  margin-bottom: 50px;
}
.block {
  border-radius: 8px;
  border: 1px solid #dbe0f2;
  padding: 16px;
  margin-bottom: 8px;
  font-size: 14px;
  transition: 0.5s;
  color: white;
}
.component {
  margin-bottom: 10px;
}
.block-component {
  font-weight: 500;
  margin-bottom: 4px;
}
.block-value {
  word-break: break-word;
}
.block-buttons {
  display: flex;
  justify-content: space-between;
}
.button-component {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  transition: 0.5s;
}
.button-component img {
  margin-right: 8px;
}
