.header {
  height: 56px;
  background-color: rgba(0, 0, 0, 0.30);
  width: 100%;
  position: fixed;
  z-index: 10000;
  display: flex;
  justify-content: center;
}
.header_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.header-logo {
  margin-right: auto;
  height: 32px;
  width: auto;
}

.menu-button-cross {
  height: 28px;
  width: auto;
  z-index: 999999;
}

@media (max-width: 1024px) {
  .menu-button {
    height: 15px;
    width: auto;
  }
  .background-active {
    height: 100vh;
    width: 100vw;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.4);
    top: 0;
    left: 0;
    z-index: 10;
  }
  .header {
    padding: 12px 0;
  }
  .header_wrapper {
    width: 608px;
  }
}

@media (max-width: 768px) {
  .background-active {
    display: none !important;
  }
  .header {
    height: 44px;
    padding: 6px 16px;
  }
  .menu-button {
    height: 10px;
    width: auto;
  }
}
