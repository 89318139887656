.wrapper {
  background-color: rgba(0, 0, 0, 0.30);
  padding: 12px 24px;
  color: #DBE0F2;
  font-size: 16px;
  font-weight: 400;
  border-radius: 8px;
  margin-bottom: 32px;
  display: flex;
}
.block {
  display: flex;
  margin-right: 24px;
}
.item {
  margin-left: 4px;
  color:#DBE0F2;
  font-weight: 500;
  display: flex;
  gap : 12px;
  align-items: center;
}

.item p {
  display: flex;
  align-items: center;
  gap : 2px;
}

@media (max-width: 767px) {
  .wrapper {
    display: block;
    padding: 12px 16px;
    margin-bottom: 24px;
  }
  .block:not(:last-child) {
    margin-bottom: 8px;
  }
}
