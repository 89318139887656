.applications_title {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 8px;
  color: white;
}

.change_amount:hover {
  cursor: pointer;
}

.no-data-icon {
  display: flex;
  justify-content: center;
}
