.wrapper {
  display: flex;
  width: fit-content;
  gap: 16px;
  height: 20px;
}
.wrapper img {
  width: 20px;
  height: 20px;
  transition: 0.5s;
}
.wrapper img:hover {
  opacity: 0.5;
  cursor: pointer;
}
