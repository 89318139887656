.inputBlock {
  display: flex;
  flex-direction: column;
  gap: 4px;
  color: white;
}

.title {
  font-size: 14px;
  font-weight: 400;
  color: white;
  display: flex;
  gap: 4px;
}

.field {
  font-size: 14px;
  width: 100%;
  border: 1px solid #696E7E;
  padding: 10px 12px;
  border-radius: 4px;
  color: white;
  background-color: rgba(0, 0, 0, 0.30);
}
.field.error {
  border: 1.5px solid #fe5b37;
}
.field:focus {
  color: white;
  outline: none;
}

.field::placeholder {
  color: #8e95b0;
}

.input {
  display: flex;
  position: relative;
}
.input img {
  height: auto !important;
  position: absolute;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
}

.imgLeft {
  left: 12px;
}
.imgRight {
  right: 12px;
}
.imgRight:hover {
  cursor: pointer;
}
.errorSpan {
  color: #EA3323;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}