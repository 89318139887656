.title {
  font-weight: 500;
  font-size: 20px;
  color: white;
  margin-bottom: 16px;
}
.block {
  display: flex;
  font-weight: 500;
  font-size: 16px;
  color: white;
  margin-bottom: 16px;
}
.block p {
  font-weight: 400;
  margin-left: 12px;
}

@media (max-width: 1024px) {
  .block {
    display: block;
  }
  .block p {
    margin-left: 0;
  }
}
