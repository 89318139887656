.button {
  background-color: #1890ff;
  border: 2px solid #1890ff;
  padding: 8px 16px;
  border-radius: 4px;
  text-align: center;
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  opacity: 1;
  transition: 0.3s;
}

.button.notFilled {
  background-color: #393B40;
  color: #1890ff;
}

.button:hover {
  border: 2px solid #1890ff;
  opacity: 0.5;
  cursor: pointer;
  outline: none;
}

.button:active {
  border: 2px solid #1890ff;
  outline: none;
}

.button.disabled {
  background-color: #e2e5f0;
  color: #8e95b0;
  border: 2px solid #e2e5f0;
}

.button.disabled:active {
  border: 2px solid #e2e5f0;
}

.button.disabled:hover {
  border: 2px solid #e2e5f0;
}
