* {
  box-sizing: border-box;
}

.card_title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 16px;
  color: white;
}
.card_active {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  color: white;
}

.add_card_button {
  position: absolute;
  right: 0;
  top: 0;
  width: 9vw;
  height: 4vh;
  font-size: 1vw;
  border-radius: 4px;
  background: #1890ff;
  border: 1px solid #1890ff;
  color: white;
  margin-bottom: 16px;
}

.forget_password_title {
  font-weight: 500;
  font-size: 1.7vh;
}
.forget_password_text {
  margin-top: -0.5vh;
  font-weight: 400;
  font-size: 1.5vh;
  margin-bottom: 1vh;
}

.link-style {
  text-decoration: none;
}

.add_card_input {
  box-shadow: none !important;
}

@media (max-width: 1024px) {
  .add_card_button {
    position: static;
    height: 36px;
    width: 142px;
    font-size: 14px;
    margin-bottom: 24px;
  }
}

@media (max-width: 767px) {
  .wrapper {
    padding: 80px 16px 16px 16px;
  }
}
