.switch_cards {
  background-color: rgba(0, 0, 0, 0.30);
  margin-bottom: 16px;
  width: 157px;
  border-radius: 35px;
  justify-content: center;
  padding: 1px;
  display: flex;
}
.switch_cards_off,
.switch_cards_on {
  width: 100%;
  border: none;
  background-color: transparent;
  padding: 2px 12px;
  font-weight: 400;
  font-size: 12px;
  opacity: 1;
  transition: 0.5s;
  color: white;
}
.switch_cards_off:hover,
.switch_cards_on:hover {
  cursor: pointer;
  opacity: 0.5;
}

.switch_cards_off.active,
.switch_cards_on.active {
  background-color: #1890ff;
  color: #ffffff;
  border-radius: 60px;
}

@media (max-width: 1024px) {
  .switch_cards {
    background-color: transparent;
    width: 100%;
    border-radius: 0px;
    padding: 1px;
  }
  .switch_cards input {
    background-color: #393B40;
    border-radius: 60px;
    height: 32px;
  }
  .switch_cards input:nth-child(1) {
    margin-right: 16px;
  }
}
